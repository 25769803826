/* CSS Document */

/* 
    Created on   : 23/05/2022.
    Theme Name   :Talk to PDF:  PDF Clarity, just a Conversation Away
    Version      : 1.0.
    Developed by : hasanlab1999@gmail.com
   
*/
/**
* Importing necessary  Styles.
**/
/*----- Fonts ----*/
@import url("./fonts/gordita/stylesheet.css");
/*---- Navbar Css------*/

/*----- Bootstrap Icons ----------*/
@import url("./fonts/bootstrap-icons/font-css.css");
/*---- Font Awesome Icons -------*/
@import url("./fonts/font-awesome/css/all.min.css");

@import "./scss/animation";
@import "./scss/slick";
@import "./scss/offcanvas";
@import "./scss/bootstrap";
@import "./scss/framework";
@import "./scss/navbar";
@import "./scss/habilidades";
@import "./scss/style";
@import "./scss/responsive";
